.hexagon {
    aspect-ratio: 1; 
    overflow: hidden;
    clip-path: polygon(
    25% 0%,   /* Top-left */
    75% 0%,   /* Top-right */
    100% 50%, /* Right */
    75% 100%, /* Bottom-right */
    25% 100%, /* Bottom-left */
    0% 50%    /* Left */
  );
  }

  .hexagon img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }

  